.qna_list {
  .qna_page_title {
    font-weight: bold;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
      text-align: center;
    }
  }
  .qna_intro {
    margin-top : 50px;
    padding-top : 50px;
    padding-bottom : 50px;
    background-color: #f0faf0;
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (max-width: 768px) {
    }
    .left {
      text-align: center;
      @media screen and (min-width: 769px) {
        width : 50%;
      }
      @media screen and (max-width: 768px) {
        font-size: 5vw;
      }
      .qna_title {
        font-weight: bold;
        @media screen and (min-width: 769px) {
          font-size: 1.8em;
        }
        @media screen and (max-width: 768px) {
          font-size: 1.5em;
        }
      }
      .qna_message {
        margin-top : 10%;
        @media screen and (min-width: 769px) {
          font-size: 1.3em;
        }
        @media screen and (max-width: 768px) {
          font-size: 1em;
          text-align: left;
          padding : 10px;
        }
      }
    }
    .right {
      @media screen and (min-width: 769px) {
        width : 50%;
      }
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
      img {
        width : 100%;
      }
    }
  }
  .qna_boxes {
    margin-top: 50px;
    background-color: #f0f0fa;
    @media screen and (min-width: 769px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 10px;
      padding-bottom: 50px;
      font-size: 5vw;
    }
  }
}
