.telephone_contact {
  margin-top : 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .telephone_message {
    @media screen and (min-width: 769px) {
      width : 50%;
    }
    @media screen and (max-width: 768px) {
      width : 100%;
    }
    .telephone_title {
      text-align: center;
      @media screen and (min-width: 769px) {
        font-size: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 5vw;
      }
    }
    .telephone_image {
      width : 30%;
      margin : 0 auto;
      img {
        width : 100%;
      }
    }
    .telephone_list {
      text-align: center;
      @media screen and (min-width: 769px) {
        font-size: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 4vw;
      }
      .telephone_question {

      }
    }
    .telephone_number {
      margin-top : 30px;
      text-align: center;
      font-size: 30px;
      a {
        color: green;
        text-decoration: none;
      }
    }
  }
}
