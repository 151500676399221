.inquiry {
  .switch {
    margin-top: 50px;
    display: flex;
    text-align: center;
    @media screen and (min-width: 769px) {
      font-size: 25px;
    }
    @media screen and (max-width: 768px) {
      font-size: 5vw;
      align-items: center;
      justify-content: center;
    }
    .inquiry_btn {
      cursor: pointer;
      text-align: center;
      background-color: #faf0f0;
      padding : 20px;
      @media screen and (min-width: 769px) {
        margin-left: 30%;
        width : 20%;
      }
      @media screen and (max-width: 768px) {
      }
    }
    .reservation_btn {
      cursor: pointer;
      @media screen and (min-width: 769px) {
        width : 20%;
        margin-right: 30%;
      }
      @media screen and (max-width: 768px) {
      }
      text-align: center;
      background-color: #f0faf0;
      padding : 20px;
    }
  }
  .title {
    // margin-top: 50px;
    font-weight: bold;
    text-align: center;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
  .form_wrapper {
    width : 80%;
    max-width : 800px;
    margin : 0 auto;
    margin-top: 50px;
    background-color: #faf0f0;
    &.reservation {
      background-color: #f0faf0;
      .label {
        color : blue;
      }
    }
    @media screen and (min-width: 769px) {
      padding : 3%;
    }
    @media screen and (max-width: 768px) {
      padding : 10%;
      font-size: 4vw;
    }
    .form {
      .form_top {
        @media screen and (min-width: 769px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media screen and (max-width: 768px) {
        }
        .form_left_side {
          @media screen and (min-width: 769px) {
            width : 50%;
          }
          @media screen and (max-width: 768px) {

          }
        }
        .form_right_side {
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (min-width: 769px) {
            width : 50%;
          }
          @media screen and (max-width: 768px) {
            display: none;
          }

          .tell_area {
            cursor : pointer;
            padding : 5%;
            margin-left: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: white;
            .tell_message {
              .tel_counselor {
                display: flex;
                justify-content: center;
                img {
                  width : 50%;
                }
              }
            }
            .tel_button {
              font-size : 22px;
              text-decoration: none;
            }
            .tel_title {
              font-size: 20px;
            }
            .tel_number {
              font-size: 20px;
              cursor : pointer;
            }
          }

        }
      }
      .input_title {
        font-size: 1.2em;
      }
      input[type="text"],input[type="tel"],input[type="email"] {
        height: 2em;
        border-style: none;
        @media screen and (min-width: 769px) {
          width : 100%;
        }
        @media screen and (max-width: 768px) {
          width : 90%;
          font-size: 4vw;
        }
        &:focus {
           outline-color : #f0f0f0;
        }
        &.email_check.red {
          color : red;
        }
      }
      input[type="submit"] {
        border-color: #fafaf0;
        background-color: #f0fafa;
        &:focus {
          color : skyblue;
          outline-color : skyblue;
        }
        @media screen and (min-width: 769px) {
        }
        @media screen and (max-width: 768px) {
          font-size: 4vw;
        }
      }
      textarea {
        width : 100%;
        border-style: none;
        &:focus {
          outline-color : #f0f0f0;
        }
        @media screen and (min-width: 769px) {
          height: 12em;
        }
        @media screen and (max-width: 768px) {
          height: 10em;
          font-size: 3vw;
        }
      }
    }
  }
}
