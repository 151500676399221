.target {
  padding : 10px;
  background-color: #f0faf0;
  @media screen and (min-width: 769px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
  .subtitle {
    text-align: center;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.5em;
    }
  }
  .target_list {
    background-color: white;
    margin-top : 2%;
    margin-bottom : 3%;
    color : green;
    padding : 20px;
    @media screen and (min-width: 769px) {
      text-align: center;
      font-size: 1.3em;
      margin-left: 10%;
      margin-right: 10%;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.1em;
    }
  }
  .targetlist_title {
    text-align: center;
    @media screen and (min-width: 769px) {
      font-size: 25px;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.5em;
    }
  }
  .target_detail {
    @media screen and (min-width: 769px) {
      font-size: 20px;
      margin-left: calc(50% - 250px);
    }
    @media screen and (max-width: 768px) {
      ul {
        padding-inline-start : 8vw;
      }
    }
  }
  .caution {
    color : red;
    font-size: 0.8em;
    font-weight: lighter;
    text-align: center;
  }
  .postscript {
    text-align: center;
    font-size: 1.1em;

  }
}
