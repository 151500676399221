.contents {
  .menu {
    max-width : 800px;
    margin: 0 auto;
    margin-top : 50px;
    @media screen and (min-width: 769px) {
      width : 80%;
    }
    @media screen and (max-width: 768px) {
      width : 100%;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item {
      cursor: pointer;
      color : white;
      background-color: ForestGreen;
      text-align: center;
      font-weight: bold;
      font-size: 1.1em;
      @media screen and (min-width: 769px) {
        padding : 30px 10px 30px 10px;
        margin : 10px;
        width : 30%;
      }
      @media screen and (max-width: 768px) {
        font-size: 5vw;
        padding : 1vw;
        margin : 1vw;
        width : 30vw;
        height : 29vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .info {
    }
    .fee {
    }
    .slogan {
    }
    .place {
    }
    .home_work {
    }
    .target   {
    }
  }

}
