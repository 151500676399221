.contents .flow {
  margin-top : 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f0faf0;
  @media screen and (min-width: 769px) {
    padding : 30px;
  }
  @media screen and (max-width: 768px) {
  }
  .title {
    text-align: center;
    margin-bottom: 30px;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
  .flow_group {
    margin: 0 auto;
    justify-content: center;
    margin-top: 50px;
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (max-width: 768px) {
    }
    .flow_info {
      background-color: white;
      padding : 20px;
      @media screen and (min-width: 769px) {
        width : 20%;
        margin-left: 10px;
        margin-right: 10px;
      }
      @media screen and (max-width: 768px) {
        margin-left: 5%;
        margin-right: 5%;
      }
      .subtitle {
        color : Teal;
        font-weight: bold;
        @media screen and (min-width: 769px) {
          font-size: 1.5em;
        }
        @media screen and (max-width: 768px) {
          font-size: 6vw;
        }

      }
      .message {
        @media screen and (min-width: 769px) {
        }
        @media screen and (max-width: 768px) {
          font-size: 5vw;
        }
        .description {
          font-size: 0.8em;
        }
      }
    }
  }
}
