.contents  .location {
  margin-top : 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  @media screen and (min-width: 769px) {
    background-color: #f0f0fa;
  }
  @media screen and (max-width: 768px) {
  }
  .title {
    text-align: center;
    margin-bottom: 30px;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
  .box {
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (max-width: 768px) {
      font-size: 5vw;
    }
    .subtitle {
      @media screen and (min-width: 769px) {
        width : 50%;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
      margin: 0 auto;
      img {
        width : 100%;
      }
    }
    .left {
      @media screen and (min-width: 769px) {
        margin : 5%;
        width : 40%;
      }
      @media screen and (max-width: 768px) {
      }
      .subtitle {

      }
      .location_info {
        background-color: #fafafa;
        padding : 20px;
        font-size: 1.2em;
        @media screen and (min-width: 769px) {
        }
        @media screen and (max-width: 768px) {
          // width : 90%;
          padding : 5%;
        }
        .company_name {
          font-weight: bold;
        }
      }
    }
    .right {
      @media screen and (min-width: 769px) {
        width : 40%;
      }
      @media screen and (max-width: 768px) {
        // width : 90%;
        margin : 0 auto;
        padding : 5%;
      }
      .map {
        width : 100%;
        @media screen and (min-width: 769px) {
          height : 100%;
        }
        @media screen and (max-width: 768px) {
          height : 300px;
        }
        iframe {
          border-style: none;
          width : 100%;
          height : 100%;
        }
      }
    }
  }
}
