.workinfo_title {
  font-weight: bold;
  @media screen and (min-width: 769px) {
    font-size: 30px;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 8vw;
  }
}

.working_list {
  margin-top : 50px;
  text-align: center;
  font-weight: bold;
  color : green;
  @media screen and (min-width: 769px) {
    font-size: 1.8em;
  }
  @media screen and (max-width: 768px) {
    font-size: 8vw;
  }
}
