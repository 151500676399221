.header {
  background-color: white;
  z-index : 1000000000;
  @media screen and (min-width: 769px) {
  }
  @media screen and (max-width: 768px) {
    position : fixed;
    width : 100%;
    top : 0;
  }
}
