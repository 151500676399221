.contents .contact {
  margin-top: 50px;
  padding-top : 50px;
  padding-bottom : 50px;
  background-color: #f0f0f0;
  .title {
    text-align: center;
    margin-bottom: 30px;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
  .subtitle {
    width : 90%;
    text-align: center;
    margin : 0 auto;
    @media screen and (min-width: 769px) {
    }
    @media screen and (max-width: 768px) {
      font-size: 4.5vw;
    }
  }
  .contact_box {
    width : 90%;
    margin : 0 auto;
    margin-top: 30px;
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (max-width: 768px) {
      font-size: 5vw;
    }
    .left {
      @media screen and (min-width: 769px) {
        width : 40%;
      }
      @media screen and (max-width: 768px) {
      }
      .contact_info {
        background-color: white;
        padding : 30px;
      }
    }
    .right {
      @media screen and (min-width: 769px) {
        width : 40%;
        margin-left: 10%;
      }
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
      .contactbtn {
        cursor : pointer;
        padding : 20px;
        background-color: green;
        color : white;
        font-size: 1.2em;
        font-weight: bold;
        text-align: center;
      }
      .contact_description {
        text-align: right;
      }
    }
  }

}
