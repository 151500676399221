.qna_boxes .qna_box {
  margin: 0 auto;
  margin-top: 50px;
  @media screen and (min-width: 769px) {
    width : 80%;
  }
  @media screen and (max-width: 768px) {
    width : 90%;
  }
  .question_box {
    background-color: #faf0f0;
    padding : 20px;
    @media screen and (min-width: 769px) {
      font-size: 1.3em;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  .answer_box {
    background-color: white;
    padding : 20px;
    font-size: 1.1em;
  }
}
