.business {
  .title {
    margin-top : 50px;
    text-align: center;
    font-weight: bold;
    color : green;
    @media screen and (min-width: 769px) {
      font-size: 1.8em;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
}
