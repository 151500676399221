.top_menu {
  @media screen and (min-width: 769px) {
    display: flex;
  }
  @media screen and (max-width: 768px) {
    width : 100%;
  }

  .logo {
    cursor: pointer;
    @media screen and (min-width: 769px) {
      margin-top : 10px;
      width : 200px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 2%;
      width :20%;
    }
    img {
      width : 100%;
    }

  }

  .menu {
    text-align: right;
    position : absolute;
    right: 0;
    padding : 30px;
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
    .contact {
      cursor: pointer;
      background-color: green;
      color : white;
      padding : 20px;
    }
    .qna {
      cursor: pointer;
      margin-left: 50px;
      background-color: DarkCyan;
      color : white;
      padding : 20px;
    }
  }
}
