.about {
  .about_title {
    font-weight: bold;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 8vw;
    }
  }
  .message_group {
    margin-top: 50px;
    background-color: #f0f0fa;
    @media screen and (min-width: 769px) {
      display: flex;
      padding : 20px;
    }
    @media screen and (max-width: 768px) {
      padding : 10px;
      font-size: 4vw;
    }
    .left {
      text-align: center;
      @media screen and (min-width: 769px) {
        width : 50%;
        font-size: 1.2em;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.1em;
        margin-top : 30px;
      }
      .title {
        font-size: 1.5em;
        font-weight: bold;
        margin-top : 3%;
        @media screen and (min-width: 769px) {
        }
        @media screen and (max-width: 768px) {
        }
      }
      .message {
        margin-top: 1%;
      }
    }
    .right {
      @media screen and (min-width: 769px) {
        width : 50%;
      }
      @media screen and (max-width: 768px) {
      }
      img {
        width : 100%;
      }
    }
  }

  .appearance {
    position : relative;
    margin-top: 50px;
    background-color: #faf0fa;
    .title {
      padding-top: 50px;
      text-align: center;
      font-weight: bold;
      @media screen and (min-width: 769px) {
        font-size: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 8vw;
      }
    }
    .appearance_info {
      margin-top: 50px;
      @media screen and (min-width: 769px) {
        display: flex;
      }
      @media screen and (max-width: 768px) {
        font-size: 4.5vw;
      }
      .left {
        text-align: right;
        @media screen and (min-width: 769px) {
          width : 50%;
        }
        @media screen and (max-width: 768px) {
        }
        .bld_img {
          @media screen and (min-width: 769px) {
            margin-left: 25%;
            width : 70%;
          }
          @media screen and (max-width: 768px) {
          }
          img {
            width : 100%;
          }
        }
      }
      .right {
        @media screen and (min-width: 769px) {
          width : 50%;
        }
        @media screen and (max-width: 768px) {
        }
        .message {
          padding : 5%;
          font-size: 1.2em;
          @media screen and (min-width: 769px) {
            width : 70%;
            background-color:white;
          }
          @media screen and (max-width: 768px) {
          }
        }
        .map {
          @media screen and (min-width: 769px) {
          }
          @media screen and (max-width: 768px) {
            margin: 0 auto;
          }
          iframe {
            border-style: none;
            @media screen and (min-width: 769px) {
              width : 80%;
              min-height : 250px;
            }
            @media screen and (max-width: 768px) {
              width : 100%;
              margin : 0 auto;
              min-height : 40vh;
            }
          }
        }
      }
    }
  }

}
