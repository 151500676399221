.pc {
  @media screen and (min-width: 769px) {

  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}


.sp {
  @media screen and (min-width: 769px) {
    display: none;
  }
  @media screen and (max-width: 768px) {

  }
}

body {
  max-width: 1800px;
  margin : 0 auto;
  position : relative;
}
