.fee_detail {
  background-color: #f0f0f0;
  padding : 3%;
  @media screen and (min-width: 769px) {
    line-height: 2.4em;
  }
  @media screen and (max-width: 768px) {
  }
  .message {
    @media screen and (min-width: 769px) {
      font-size: 1.5em;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      font-size: 5vw;
    }
    .caution {
      font-size: 0.8em;
      color : OrangeRed;
    }
    .point {
      color : green;
    }
    .postscript {
      font-size: 0.8em;
      @media screen and (min-width: 769px) {
        text-align: center;
      }
      @media screen and (max-width: 768px) {
      }
    }
  }
}
