.footer {
  text-align: center;
  background-color: #f0faf0;
  margin-top: 50px;
  @media screen and (min-width: 769px) {
    padding : 50px;
  }
  @media screen and (max-width: 768px) {
    font-size: 3vw;
    padding : 6.5vw;
  }
}
