.contents  .info {
  margin-top : 50px;
  background-color: #f0f0f0;
  padding-top : 50px;
  padding-bottom : 50px;
  .title {
    text-align: center;
    font-size: 30px;
    @media screen and (min-width: 769px) {
      font-size: 30px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size : 8vw;
    }
  }
  .row {
    width : 80%;
    margin : 0 auto;
    background-color: white;
    @media screen and (min-width: 769px) {
      width : 80%;
    }
    @media screen and (max-width: 768px) {
      width : 90%;
      padding-top: 6vw;
      padding-bottom : 6vw;
      margin-top : 3vw;
    }
    .sub_title {
      text-align: center;
      color : green;
      vertical-align: middle;
      @media screen and (min-width: 769px) {
        border-right: 1px solid black;
        display: table-cell;
        width : 200px;
      }
      @media screen and (max-width: 768px) {
        font-size : 8vw;
      }
    }
    .message {
      @media screen and (min-width: 769px) {
        display: table-cell;
        padding : 30px;
      }
      @media screen and (max-width: 768px) {
        padding : 1em;
        font-size : 5vw;
      }
      .red {
        color : red;
      }
    }
  }
}
