.topButton {
  position : fixed;
  right : 5%;
  bottom : 10%;
  color : MediumSlateBlue;
  opacity: 0.6;
  cursor: pointer;
  @media screen and (min-width: 769px) {
    font-size: 50px;
  }
  @media screen and (max-width: 768px) {
    font-size: 10vw;
  }
}
