.contents {
  width : 90%;
  margin : 0 auto;
  @media screen and (min-width: 769px) {
    padding-top: 50px;
  }
  @media screen and (max-width: 768px) {
    padding-top: calc(50px + 15vw);
  }
  .main_img {
    @media screen and (min-width: 769px) {
      width : 80%;
    }
    @media screen and (max-width: 768px) {
      width : 100%;
    }
    border-radius: 50px;
    margin: 0 auto;
    overflow: hidden;
    img {
      width : 100%;
    }
  }

}
