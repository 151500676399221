.home_work {
   margin-top: 50px;
   padding-top: 50px;
   background-color: #f0f0fa;
   @media screen and (min-width: 769px) {
   }
   @media screen and (max-width: 768px) {
     font-size : 4vw;
   }
  .subtitle_box {
    font-weight: bold;
    text-align: center;
    margin-left : 10%;
    margin-right: 10%;
    @media screen and (min-width: 769px) {
      font-size : 2.2em;
    }
    @media screen and (max-width: 768px) {
      font-size : 1.2em;
      padding : 5%;
    }
  }
  .message_box {
    padding : 10px;
    background-color: #f0fafa;
    .tele_title {
      text-align: center;
      @media screen and (min-width: 769px) {
        font-size: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.4em;
        padding : 5%;
      }
    }
    .apricot_box {
      background-color: white;
      margin-top : 2%;
      margin-bottom : 3%;
      color : green;
      padding : 20px;
      @media screen and (min-width: 769px) {
        margin-left: 10%;
        margin-right: 10%;
        font-size: 1.3em;
        text-align: center;
      }
      @media screen and (max-width: 768px) {
        font-size: 1em;
      }
    }
    .support_box {
      margin-top : 2%;
      margin-bottom : 3%;
      .inner_support_box {
        @media screen and (min-width: 769px) {
          margin : 0 auto;
          font-size: 1.2em;
          width : 24em;
        }
        @media screen and (max-width: 768px) {
          font-size: 1.2em;
        }
      }
    }
  }
}

.arrow_box {
  @media screen and (min-width: 769px) {
    display: flex;
    max-width: 1000px;
    margin : 0 auto;
  }
  @media screen and (max-width: 768px) {
  }
  .arrow {
    @media screen and (min-width: 769px) {
      margin-left: -2%;
      width : 27%;
    }
    @media screen and (max-width: 768px) {
    }
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &.yellow {
      background-image: url('../../img/process_yellow.png');
      @media screen and (min-width: 769px) {
        // background-image: url('../img/process_yellow.png');
      }
      @media screen and (max-width: 768px) {
      }
    }
    &.green {
      background-image: url('../../img/process_green.png');
      @media screen and (min-width: 769px) {
        // background-image: url('../img/process_green.png');
      }
      @media screen and (max-width: 768px) {
      }
    }
    .arrow_text {
      padding : 0 22%;
      position: relative;
      font-weight: bold;
      margin-left: 3%;
      @media screen and (min-width: 769px) {
      }
      @media screen and (max-width: 768px) {
        font-size: 5vw;
      }
      .arrow_title {
        padding-top: 10%;
        @media screen and (min-width: 769px) {
          text-align: right;
        }
        @media screen and (max-width: 768px) {
          margin-left: 40px;
        }
      }
      .arrow_number {
        position : absolute;
        top : 0;
        left : 10%;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        @media screen and (min-width: 769px) {
          line-height: 3.4em;
          width : 60px;
          height : 60px;
        }
        @media screen and (max-width: 768px) {
          line-height: 13vw;
          width : 13vw;
          height : 13vw;
        }
      }
    }
  }
}

.checklist {
  .check_message {
    @media screen and (min-width: 769px) {
      font-size : 1.2em;
      line-height: 2.4em;
    }
    @media screen and (max-width: 768px) {
      font-size : 4vw;
      line-height: 1.8em;
      width : 90%;
      margin : 0 auto;
    }
  }
}
