.intro {
  background-color: #f0faf0;
  margin-top : 50px;
  @media screen and (min-width: 769px) {
    display: flex;
    padding : 20px;
  }
  @media screen and (max-width: 768px) {
    padding-left : 5%;
    padding-top: 50px;
  }
  .left {
    @media screen and (min-width: 769px) {
      margin-top: 30px;
      width : 50%;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      font-size: 4vw;
    }
    .intro_title {
      font-size: 1.8em;
      font-weight: bold;
      @media screen and (min-width: 769px) {
      }
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
    .intro_message {
      margin-top: 50px;
      font-size: 1.2em;
    }
  }

  .right {
    @media screen and (min-width: 769px) {
      width : 50%;
    }
    @media screen and (max-width: 768px) {
    }
    img {
      width : 100%;
    }
  }
}
