.contents .support {
  margin-top : 50px;
  background-color: #faf0f0;
  padding-top: 50px;
  padding-bottom: 50px;
  .title {
    text-align: center;
    margin-bottom: 50px;
    @media screen and (min-width: 769px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
  .check_list {
    // margin-left: calc(50% - 500px);
    margin-left: 3%;
    margin-right: 3%;
    .row {
      @media screen and (min-width: 769px) {
        display: flex;
        border-bottom: 1px dotted;
        margin-top: 1em;
      }
      @media screen and (max-width: 768px) {

      }
      .checkMessage {
        @media screen and (min-width: 769px) {
          width : 50%;
          font-size: 20px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 1em;
          font-size: 5.5vw;
        }
        .check_icon {
          display: inline-block;
          color : green;
          font-size: 1.2em;
          @media screen and (min-width: 769px) {
            font-size: 1.2em;
          }
          @media screen and (max-width: 768px) {
            font-size: 5vw;
          }
        }
      }
    }
  }
}
