.navigation {
  color : green;
  text-align: center;
  border-collapse: collapse;
  @media screen and (min-width: 769px) {
    margin-top: 50px;
    border: thin solid gray;
  }
  @media screen and (max-width: 768px) {
    // position : absolute;
    top : 0;
    width : 100%;
    z-index: 1000000000;
    border-bottom: thin solid gray;
  }
  .three_bar{
    position : absolute;
    display: block;
    top : 0;
    right : 0;
    margin : 3.5vw;
    font-size: 8vw;
    @media screen and (min-width: 769px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
    }
  }
  .items {
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (max-width: 768px) {
      padding-bottom : 2%;
    }
    .item {
      cursor: pointer;
      padding : 10px;
      padding-top : 15px;
      @media screen and (min-width: 769px) {
        border-right: thin solid gray;
        width : 19%;
      }
      @media screen and (max-width: 768px) {
        background-color: white;
        display: none;
        font-size : 3.5vw;
        &.opened {
          display: block;
        }
        &:first-child {
          background-color: unset;
        }
      }
      &.selected {
        background-color: #f0faf0;
      }
    }
    .item:last-child {
      border-right: none;
    }
    .home {
      // font-size: 25px;
      @media screen and (min-width: 769px) {
        // width : calc(100% - 18% * 5);
      }
      @media screen and (max-width: 768px) {
      }
    }
    .about {
    }
    .introduce {
    }
    .work_info {
    }
    .qna {
    }
    .inquiry {
    }
  }
}
