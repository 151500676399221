.user_voice {
  margin : 50px auto;
  background-color: #faf0f0;
  padding : 3%;
  @media screen and (min-width: 769px) {
    text-align: left;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  }
  .profil {
    padding : 1.5%;
    font-weight: normal;
    @media screen and (min-width: 769px) {
      width : 23%;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 30px;
    }
    .user_name {
      text-align: center;
      font-size: 2em;
      @media screen and (min-width: 769px) {
        margin-bottom: 1%;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
    .avatar {
      width : 50%;
      margin : 0 auto;
    }
    img {
      border-radius: 50%;
      width : 100%;
      margin : 0 auto;
    }
  }
  .vertical_line {
    border-width : thin;
    border-style: solid;
    height: auto;
    @media screen and (min-width: 769px) {
      border-left: black;
      margin-right : 1%;
      margin-top : 1em;
      margin-bottom : 1em;
    }
    @media screen and (max-width: 768px) {
      border-bottom: black;
    }
  }
  .user_message {
    padding : 1%;
    @media screen and (min-width: 769px) {
      width : 70%;
    }
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .user_name {
      text-align: center;
      font-size: 2em;
      @media screen and (min-width: 769px) {
        margin-bottom: 1%;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }
}
